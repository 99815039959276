import { EXHIBITOR_WITH_URLS_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/WithUrls';

export const LARGE_PRODUCT_ENTITY_INFO_EXHIBITOR_FRAGMENT = `
  fragment largeProductEntityInfoExhibitorFragment on LargeProduct {
    uid
    schemaCode
    name
    exhibitor {
      ...exhibitorWithUrlsFragment
    }
  }
  ${EXHIBITOR_WITH_URLS_FRAGMENT}
`;
